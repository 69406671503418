export default [
  {
    key: 'checkbox',
    label: '',
    sortable: false,
    class: 'text-center align-middle text-uupercase',
    show: true,
    type: 'checkbox',
    thClass: 'align-middle text-center text-uupercase',
  },
  {
    key: 'billing_month',
    label: 'Date',
    sortable: true,
    class: 'text-center align-middle text-uupercase',
    show: true,
    thClass: 'align-middle text-center text-uupercase',
  },
  {
    key: 'publisher_name',
    label: 'Publisher',
    sortable: false,
    class: 'text-center align-middle text-uupercase',
    show: true,
    thClass: 'align-middle text-center text-uupercase',
  },
  {
    key: 'impressions',
    label: 'Impressions',
    sortable: false,
    class: 'text-center align-middle text-uupercase',
    show: true,
    thClass: 'align-middle text-center text-uupercase',
  },
  // {
  //   key: 'cost_rate',
  //   label: 'Rate',
  //   sortable: false,
  //   class: 'text-center align-middle text-uupercase',
  //   show: true,
  //   type: 'currency',
  //   thClass: 'align-middle text-center text-uupercase',
  // },
  {
    key: 'cost_total',
    label: 'Total Cost',
    sortable: false,
    class: 'text-center align-middle text-uupercase',
    show: true,
    thClass: 'align-middle text-center text-uupercase',
  },

]
