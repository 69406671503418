
import ViewModel from '@/models/ViewModel'
import Widget from '@/components/Widget/Widget.vue'
import {
  Component, Ref, Vue, Watch,
} from 'vue-property-decorator'
import FormInput from '@/components/FormInput/FormInput.vue'
import Reconciliation from '@/models/Reconciliation'
import FooterNav from '@/components/FooterNav/FooterNav.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import ReconciliationClient from '@/models/ReconciliationClient'
import {
  clone, uniqueId, groupBy as _groupBy, groupBy,
} from 'lodash'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import Invoice from '@/models/Invoice'
import DataTable from '@/components/DataTable/index.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import { PaginationOptionsAll } from '@/models/interface/Common'
import moment from 'moment'
import Expense from '@/models/Expense'
import WebMessage from '@/models/WebMessage'
import batchFields from './expense-batch-fields'
// import InvoiceBatchSubTable from './Components/InvoiceBatchSubTable.vue'
// import InternalSearch from './Components/InternalSearch.vue'

// import { invoice_type_options, invoice_group_options, query_settings } from '../'
@Component({
  components: {
    Widget,
    FormInput,
    DataTable,
    FooterNav,
    IconAction,
    // InvoiceBatchSubTable,
    SelectPicker,
    SearchInput,
    DatePicker,
    // InternalSearch,
  },
})
export default class ExpenseBatchCreate extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  @Ref() readonly subTable!: any

  public uuid: string = uniqueId()

  public busy: boolean = true

  public loading: boolean = false

  public sort_by: string = 'created_at'

  public sort_desc: boolean = true

  public isAllSelected: boolean = false

  public page_size: number = 25

  public page: number = 1

  public records: number = 0

  public ready: boolean = false

  public selected: string[] = []

  public fieldFilters: any = {}

  public query: string[] = []

  public fields: Array<any> = []

  public checked_group: string[] = []

  public checked_control: any = []

  public reconciliation_expenses: any = []

  public restore_reconciliation_expenses: any = []

  public showAllDetails: boolean = false

  public time_warning: any = {
    open: false,
    loading_message: 'This might take a while, please wait.',
    completed_message: 'Journals created with success.',
    loading: false,
  }

  @Watch('isAllSelected')
  public onSelectedChange(val: any) {
    if (!val) {
      this.resetSelection()
    } else {
      this.reconciliation_expenses.forEach((group: any) => {
        this.checked_group.push(group.id)
        Vue.set(
          this.checked_control,
          group.id,
          this.reconciliation_expenses.map((item: Reconciliation) => item.id),
        )
      })
    }
  }

  // Default table pagination options
  public get pagination_options() {
    return PaginationOptionsAll
  }

  //   public get options() {
  //     return { invoice_type_options, invoice_group_options, query_settings }
  //   }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  // Not 100% done must try with other types beside string
  public searchable_rules = {
    advertiser: (item: any) => item.advertiser.name,
    agency: (item: any) => item.agency.name,
    type: (item: any) => item.type,
  }

  mounted() {
    this.fields = batchFields
    this.busy = false
    // @ts-ignore
    this.$echo.private(`user.${this.user!.id}`).listen('LoadingUI', (e: any) => {
      // @ts-ignore
      // WebMessage[e.type](e.message)
      this.busy = false
      this.loading = false
      this.time_warning.loading = false
      setTimeout(() => {
        this.time_warning.open = false
        this.dataTable.refresh()
      }, 1000)
    })
  }

  /**
   *
   * This function is a trigger for the updated row item "Group by"
   *
   * If the type is media_ocean locks the property group_by to invoice_per_media_plan
   *
   * It will search in invoice_group_options for the Media Ocean default option
   *
   */
  public updateItemGroupBy(item: any, selected: any) {
    // if (selected.value === 'media_ocean' || selected.value === 'strata') {
    //   item.group_by = this.options.invoice_group_options.find(
    //     (op: any) => op.value === 'invoice_per_media_plan',
    //   )?.value
    // }
  }

  /**
   * Hide All sub items
   */
  private showAll() {
    this.showAllDetails = true
    this.toggleDetails('all', 'show')
  }

  /**
   * Show all sub items
   */
  private hideAll() {
    this.showAllDetails = false
    this.toggleDetails('all', 'hide')
  }

  private toggleDetails(target: string, action: string = 'toggle') {
    if (target === 'all') {
      this.reconciliation_expenses.forEach(group => {
        group._showItems = action === 'show' ? true : action === 'hide' ? false : !group._showItems
      })

      return
    }
    let record = this.reconciliation_expenses.find((item: any) => item.uuid === target)
    if (record) {
      record._showItems = action === 'show' ? true : action === 'hide' ? false : !record._showItems
    }
  }

  public reconciliationPaginateClients(context: any) {
    this.loading = true
    this.busy = false

    return Reconciliation.batchExpensePaginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: context.query,
    }).then(result => {
      this.records = result.records

      // this.reconciliation_expenses = groupBy(result.data, 'publisher_name')
      this.reconciliation_expenses = result.data

      this.restore_reconciliation_expenses = clone(this.reconciliation_expenses)

      this.isAllSelected = false
      this.hideAll()
      setTimeout(() => {
        this.loading = false
        // this.showAll()
        // this.isAllSelected = true
      }, 1000)
      if (!result.data.length) this.$router.push({ name: 'expenses' })
      return this.reconciliation_expenses
    })
  }

  /**
   * Triggered on group check box change, it updates the sub items check state
   */
  private toogleItems(group: ReconciliationClient) {
    // Check if group is checked
    const isChecked = this.checked_group.includes(group.uuid)
    // Vue Set is Required to ensure that the UI is updated properly on array change
    Vue.set(
      this.checked_control,
      group.uuid,
      isChecked ? group.items.map((item: Reconciliation) => item.id) : [],
    )
  }

  public get indeterminate(): boolean {
    return (
      this.checked_group.length > 0
      && this.checked_group.length < this.reconciliation_expenses.length
    )
  }

  /**
   * Check if a group has at least one checked item but not all
   */
  public isGroupIndeterminate(group: ReconciliationClient) {
    return (
      this.checked_control[group.uuid]
      && this.checked_control[group.uuid].length > 0
      && this.checked_control[group.uuid].length < group.items.length
    )
  }

  public get canSave() {
    if (this.checked_group && !this.checked_group.length) return true
    return false
  }

  public buildCheckedExpensesData() {
    let checked_expenses = this.reconciliation_expenses.filter((row: any) =>
      this.checked_group.includes(row.id))

    return checked_expenses.map((ex: any) =>
      Object.assign(new Expense(), {
        created_at: moment(ex.billing_month).endOf('month').format('YYYY-MM-DD'),
        amount: ex.cost_total,
        category: 'media_cost_ssl',
        commission_deduct: 'media_plans',
        commission_deduct_mode: 'system_defined',
        description: `SSL ${ex.impressions}`,
        name: `${ex.publisher_name} ${moment(ex.billing_month).format('MM/DD/YYYY')}`,
        client_id: ex.publisher_id,
        id: ex.id,
      }))
  }

  public saveBatch(request_approval: boolean = false, send_reminder: boolean = false) {
    this.loading = true
    this.busy = true

    let checked_expenses = this.buildCheckedExpensesData()

    Expense.createBatch(checked_expenses).then((e: any) => {
      this.busy = false
      this.loading = false
      setTimeout(() => {
        this.dataTable.refresh()
      }, 500)
    })
  }

  public get selectedItems() {
    return this.reconciliation_expenses.filter((row: any) => this.checked_group.includes(row.id))
  }

  public target_type: string = ''

  public batchCreate(type: string) {
    if (this.checked_group.length === 0) {
      WebMessage.error('Please select at least one record')
      return
    }
    this.target_type = type
    this.$bvModal.show('modal-confirm-action')
  }

  public confirmBatchCreate() {
    if (this.target_type === 'journal') {
      this.createJournal()
    } else {
      this.saveBatch(true)
    }
  }

  public createJournal() {
    this.loading = true
    this.busy = true

    let checked_expenses = this.buildCheckedExpensesData()

    this.time_warning.open = true

    this.time_warning.loading = true

    Expense.createJournal(checked_expenses, 'reconciliation')
  }

  public resetSelection() {
    Vue.set(this, 'checked_group', [])
    Vue.set(this, 'checked_control', [])
  }

  public refreshTable() {
    this.hideAll()
    this.dataTable.refresh()
    setTimeout(() => {
      this.isAllSelected = true
    }, 1000)
  }
}
